/* You can add global styles to this file, and also import other style files */
.margin-left-5 {
    margin-left: 5px !important;
}

.margin-right-5 {
    margin-right: 5px !important;
}

.margin-top-5 {
    margin-top: 5px !important;
}

.margin-top-15 {
    margin-top: 15px !important;
}

.margin-bottom-5 {
    margin-bottom: 5px !important;
}

.margin-bottom-5 {
    margin-bottom: 5px !important;
}

.margin-bottom-15 {
    margin-bottom: 15px !important;
}

.padding-left-5 {
    padding-left: 5px !important;
}

.padding-right-5 {
    padding-right: 5px !important;
}

.padding-top-5 {
    padding-top: 5px !important;
}

.padding-bottom-5 {
    padding-bottom: 5px !important;
}

.width-percent-5 {
    width: 5%;
}

.width-percent-10 {
    width: 10%;
}

.width-percent-15 {
    width: 15%;
}

.width-percent-20 {
    width: 20%;
}

.width-percent-30 {
    width: 30%;
}

.width-percent-40 {
    width: 40%;
}

.width-percent-50 {
    width: 50%;
}

.width-percent-100 {
    width: 100%;
}

/* Sweet alert */

body.swal2-height-auto {
    height: inherit !important;
}

.swal2-popup.swal2-toast {
    padding: 10px 12px !important;
    display: block !important;
}

.swal2-popup.swal2-toast .swal2-header,
.swal2-popup.swal2-toast .swal2-title,
.swal2-popup.swal2-toast .swal2-content {
    text-align: left !important;
    margin: 0 !important;
}

.swal2-popup.swal2-toast .swal2-title {
    margin-bottom: 4px !important;
}

.swal2-icon.swal2-info::before,
.swal2-icon.swal2-warning::before,
.swal2-icon.swal2-success::before,
.swal2-icon.swal2-error::before {
    content: "";
}

@keyframes glowing {
    0% {
        opacity: 0.3;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0.3;
    }
}

.glowing {
    animation: glowing 2s infinite;
}

.root-initial-loader .overlay {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(51, 51, 51, 0.8);
    z-index: 99999 !important;
    width: 100%;
    height: 100%;
}

.root-initial-loader i {
    position: absolute;
    color: rgb(91, 167, 234);
    z-index: 100000 !important;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.root-initial-loader .fa-spin-2x {
    -webkit-animation: fa-spin 1s infinite linear;
    animation: fa-spin 1s infinite linear;
}
ngx-spinner .loading-text {
    font-weight: bold;
    color: white;
    font-size: 1.3em;
}

bs-days-calendar-view + bs-days-calendar-view {
    margin-left: 10px;
}

bs-days-calendar-view {
    display: inline-block;
    border-radius: 4px 0 0 4px;
}

@media (max-width: 768px) {
    .btn-md-icon {
        margin-right: 0 !important;
        padding-right: 0 !important;
    }
}


html[data-bs-theme=dark] {
    --kt-text-muted: #9D9DA6;
}

html[data-bs-theme=dark] .dropdown-excel-operations .p-button{
    background-color: var(--bs-dropdown-bg);
}

html[data-bs-theme=dark] .dropdown-excel-operations .p-button *{
    color: #fff;
}

html[data-bs-theme=dark] .dropdown-excel-operations .p-fileupload-choose:not(.p-state-disabled):hover, 
html[data-bs-theme=dark] .dropdown-excel-operations .p-fileupload-choose:not(.p-state-disabled):focus, 
html[data-bs-theme=dark] .dropdown-excel-operations .p-fileupload-choose:not(.p-state-disabled):active {
    background-color: var(--bs-dropdown-link-hover-bg) !important;
}

[data-kt-app-sidebar-hoverable=true][data-kt-app-sidebar-minimize=on] .app-sidebar:not(.animating) .menuSearchBarInput {
    opacity: 0;
}

[data-kt-app-sidebar-hoverable=true][data-kt-app-sidebar-minimize=on] .app-sidebar:hover:not(.animating) .menuSearchBarInput {
    opacity: 1;
}

label.required:after {
    content: "*";
    color: var(--kt-form-label-color) !important;
}

.btn i.flaticon-search-1{
    padding-right:0!important;
}

li.breadcrumb-item::after{
    padding-left:0.5rem;
}

ul.p-autocomplete-items{
    margin-bottom: 0!important;
    padding-left: 0!important;
}

/*Style for Excel Operations Buttons */
.dropdown-excel-operations .p-button {
    display: block;
    margin: 0;
    text-align: left;
    border-radius: 0;
    transition: none !important;
    margin-left: -11px;
    background-color: #fff;
    color: #181C32 !important;
    font-size: 13px !important;
    font-weight: 400 !important;
    margin-left: 0px;
    margin-right: 0px !important;
}
.dropdown-excel-operations .p-fileupload-choose.p-button.p-state-hover, .p-fileupload-choose.p-button.p-state-focus, .p-fileupload-choose.p-button.p-state-active {
    background-color: #eee;
}
.dropdown-excel-operations a:hover span.p-button, .dropdown-excel-operations a:hover div.p-fileupload-buttonbar.p-widget-header.p-corner-top {
    background-color: #f4f5f8 !important;
}
.dropdown-excel-operations a.no-padding {
    padding: 0px !important;
}
.dropdown-excel-operations a .p-button-text {
    margin-left: 5px !important;
}
.dropdown-excel-operations .p-button-label {
    font-weight: inherit !important;
}
.dropdown-excel-operations .p-fileupload-choose:not(.p-state-disabled) {
    border: none;
}
.dropdown-excel-operations .p-fileupload-choose:not(.p-state-disabled):hover, .dropdown-excel-operations .p-fileupload-choose:not(.p-state-disabled):focus, .dropdown-excel-operations .p-fileupload-choose:not(.p-state-disabled):active {
    background-color: #f4f5f8 !important;
    color: #b2b1c5;
    box-shadow: none !important;
    border: none;
}
.dropdown-excel-operations div.p-fileupload-buttonbar.p-widget-header.p-corner-top {
    border: none;
    background: #fff;
    padding-bottom: 3px !important;
    padding-top: 3px !important;
}
.dropdown-excel-operations div.p-fileupload-buttonbar.p-widget-header.p-corner-top:hover {
    background-color: #f4f5f8 !important;
}
.dropdown-excel-operations div.p-fileupload-content {
    display: none;
}
.dropdown-excel-operations div.p-fileupload-content.p-widget-content.p-corner-bottom {
    display: none;
}
.dropdown-excel-operations div.p-fileupload-buttonbar {
    border: none;
    padding: 0;
}
.dropdown-excel-operations div.p-fileupload-buttonbar.p-widget-header.p-corner-top:hover {
    background-color: #f4f5f8 !important;
}

.dropdown-item {
    cursor: pointer !important;
}

.breadcrumb-item:hover {
    background-color: #f6f7fd !important;
}

/*Breacrumb end here*/

.p-multiselect {
    width: inherit !important;
    background-color: #252525 !important;
    border: 1px solid #323248 !important;
}

.p-multiselect:not(.p-disabled).p-focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none;
    border-color: #F4BC01;
  }

.subHeader-spBack {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-left: 3rem;
}

.primeng-datatable-container .primeng-paging-container {
    padding: 10px !important;
}
/* paginator style end*/

.label-danger {
    background-color: #FD6979 !important;
}

.label {
    text-shadow: none !important;
    font-size: 13px;
    font-weight: 300 !important;
    padding: 3px 6px;
    color: #fff;
}

.label-success {
    background-color: #0ABB87 !important;
}

.icon-color {
    color: #81838D !important;
}

.h-155 {
    height: 155px !important;
}

.left--200 {
    left: -180px !important;
}

/*Check box style Start*/

body .ui-chkbox .ui-chkbox-box.ui-state-active {
    border-color: #f4bc01 !important;
    background-color: #f4bc01 !important;
    color: #ffffff !important;
}

body .ui-chkbox .ui-chkbox-box {
    background-color: #252525 !important;
    border: 1px solid #373737 !important;
}

body .ui-chkbox .ui-chkbox-box.ui-state-active:not(.ui-state-disabled):hover {
    border-color: #f4bc01 !important;
    background-color: #f4bc01 !important;
    color: #ffffff;
}

/*Check box style End*/
/*Drop Down Changes */
.dropdown-menu > li > a:hover {
    background-color: #f4bc01 !important;
    color: #ffffff !important;
}

.dropdown .dropdown-menu {
    border: solid 1px !important;
    border-color: #f4bc01 !important;
    background-color: #2C2C2C !important;
}

.dropdown-menu > li > a {
    color: #ffffff !important;
}

.table-border-top {
    border-top: 6px solid #dee2e6 !important;
    border-top-color: #f4bc01 !important;
}

.action-bar-left-radius {
    border-top-left-radius: 25px !important;
    border-top-right-radius: 3px !important;
    border-bottom-left-radius: 25px !important;
    border-bottom-right-radius: 3px !important;
}

.action-bar-btn {
    color: white !important;
    background-color: #2C2C2C !important;
    border-width: 5px !important;
    width: -moz-fit-content !important;
    width: fit-content !important;
    white-space: nowrap !important;
}

.action-bar-right-radius {
    border-top-right-radius: 25px !important;
    border-bottom-right-radius: 25px !important;
    border-top-left-radius: 3px !important;
    border-bottom-left-radius: 3px !important;
}

.action-bar-btn:focus, .action-bar-btn.focus {
    background-color: #f4bc01 !important;
}

.badge-warning {
    background-color: #f4bc01 !important;
    color: #212529 !important;
}

.badge {
    border-radius: 10px !important;
    min-width: 45px !important;
    padding: 0.4em 0em !important;
    display: inline-block !important;
}

.font-yellow {
    color: #f4bc01!important;
}

/*Pop Up style start*/
.swal2-popup {
    background-color: #252525 !important;
}

.swal2-styled.swal2-confirm {
    color: #fff !important;
    background-color: #f4bc01 !important;
    border-color: #f4bc01 !important;
}

.swal2-styled.swal2-cancel {
    color: #f4bc01 !important;
    border-color: #f4bc01 !important;
    background-color: #252525 !important;
    border: 1px dashed !important;
}

.swal2-popup .swal2-title {
    color: #f4bc01 !important;
    overflow: auto !important;
    overflow-x: hidden !important;
    max-height: 30rem !important;
    scrollbar-width: thin !important; /* For Firefox */
    scrollbar-color: transparent transparent !important; /* For Chrome, Edge, and Safari */
}

.swal2-popup .swal2-title::-webkit-scrollbar {
    width: 0 !important; /* Remove scrollbar space for Chrome, Edge, and Safari */
}

/*Pop Up Style end*/

/*New Theme Components End*/
.image-upload > input {
    display: none;
}

.bdr-rad-3 {
    border-radius: 3px !important;
}

.bdr-top-bottom-right-rad-3 {
    border-top-right-radius: 3px !important;
    border-bottom-right-radius: 3px !important;
}

.clr-green {
    color: #0fb555 !important;
}

.clr-red {
    color: #ef2238 !important;
}

.flex-center {
    display: flex !important;
    justify-content: center !important;
}

.percent-progress-bar {
    height: 80px !important;
}

.p-lr-10px {
    padding-left: 10px !important;
    padding-right: 10px !important;
    padding-bottom: 0px !important;
}

.center-width-margin {
    width: 100% !important;
    margin: auto !important;
}

.clr-white {
    color: white !important;
}

@media (min-width: 1024px) and (max-width: 1800px) {
    .h-162-laptop {
        height: 162px !important;
    }

    .h-182-laptop {
        height: 182px !important;
    }
}

@media (min-width: 1801px) {
    .h-169-4k {
        height: 169px !important;
    }

    .h-160-4k {
        height: 160px !important;
    }
}

.tags-table-cell {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
}

.primeng-datatable-container .total-records-count {
    float: left !important;
    position: relative !important;
    bottom: 36px !important;
    font-size: 15px;
    margin-left: 1rem;
}

.p-datatable-resizable-table > .p-datatable-thead > tr > th,
.p-datatable-resizable-table > .p-datatable-tfoot > tr > td,
.p-datatable-resizable-table > .p-datatable-tbody > tr > td {
    white-space: normal !important;
    /*overflow-wrap: break-word !important;*/  /* Wraps long strings */
    /*word-break: break-word !important;*/  /* For older browsers */
}

.preserve-filter {
    padding-left: 10px;
    position: relative;
    bottom: 35px;
    color: #f4bc01;
}


html[data-theme=dark] {
    --kt-text-muted: #9D9DA6;
}

[data-kt-app-sidebar-hoverable=true][data-kt-app-sidebar-minimize=on] .app-sidebar:not(.animating) .menuSearchBarInput {
    opacity: 0;
}

[data-kt-app-sidebar-hoverable=true][data-kt-app-sidebar-minimize=on] .app-sidebar:hover:not(.animating) .menuSearchBarInput {
    opacity: 1;
}

.text-break-overflow {
    overflow-wrap: break-word !important;
}

.loader {
    width: 2rem;
    height: 2rem;
    vertical-align: text-bottom;
    border: 0.25em solid currentColor;
    border-right-color: transparent;
    border-radius: 50%;
    -webkit-animation: spinner-border 0.75s linear infinite;
    animation: spinner-border 0.75s linear infinite;
    color: #3498db;
    position: absolute;
    left: calc(50% - 32px / 2); /* 50 % of body width minus half of .loader size… */
    top: calc(50% - 32px / 2);  /* …and the same thing with height */
}

#loading{
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 1;
    margin: -75px 0 0 -75px;
    border: 16px solid #3498db;
    border-radius: 50%;
    border-top: 16px solid #3498db;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
    display: block;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

/* Start "Upload Tags" modal styling */
#dropFileFormForTag {
    text-align: center;
    border-radius: 8px;
    overflow: hidden;
    transition: 0.5s;
    border: 1px dashed #2c2c2c !important;
    height: 100px !important;
    background-color: #1e1e1e !important;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

/* Finish "Upload Tags" modal styling */

.nav-line-tabs .nav-item .nav-link {
    color: #ffffff !important;
}

.w-20 {
    width: 20% !important;
  }

.w-40 {
    width: 40% !important;
  }

.tagify:not(.form-control-sm):not(.form-control-lg) .tagify__tag {
    padding-right: 2rem !important;
}

.tagify .tagify__input:before {
    color: white !important;
}

.tagify.form-control {
    padding: 5px !important;
}

.progress-with-labels {
    width: 100%;
    position: relative;

    .progress-foreground {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        color: black;
    }

    .progress-background {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: #707070;
        color: white;
    }
}