.p-autocomplete-input-token {
    padding: 0px;
  }
  .p-autocomplete-multiple-container {
    width: 100%;
  }

  p-autocomplete ul.p-autocomplete-multiple-container{
    display: block !important;
    width: 100% !important;
    padding: 0.75rem 0.75rem !important;
    font-size: 1.1rem !important;
    font-weight: 500 !important;
    line-height: 1.5 !important;
    color: var(--bs-gray-700);
    background-color: var(--bs-body-bg);
    background-clip: padding-box;
    border: 1px solid var(--bs-gray-300);
    border-radius: 0.475rem !important;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;

    li{
      padding: 0.75rem 1rem;

      input{
        color: var(--bs-gray-700) !important;
        font-size: 1.1rem !important;
        font-weight: 500 !important;     
      }

      input::placeholder{
        color: var(--bs-gray-500) !important;
        font-size: 1.1rem !important;
        font-weight: 500 !important;     
      }
    }
  }

  p-autocomplete .p-inputwrapper-focus .p-autocomplete-multiple-container{
      color: var(--bs-gray-700);
      background-color: var(--bs-body-bg);
      border: 1px solid var(--bs-gray-400);
      outline: 0;
      box-shadow: inset 0 0 0 1px #9FA8DA, inset 0 0 0 1px #9FA8DA, inset 0 0 0 1px #9FA8DA, inset 0 0 0 1px #9FA8DA;
  }
  
  


 